import React from 'react'
import { Styled, css } from 'theme-ui'

const Footer = ({ socialLinks }) => (
  <footer
    css={css({
      mt: 4,
      pt: 3,
    })}
  >
    ©{' '}
    <Styled.a href='https://ahrjarrett.com/' target='_blank'>
      ahrjarrett
    </Styled.a>
    , {new Date().getFullYear()}
  </footer>
)
export default Footer
